import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = 'https://codingbeyonddsa-backend-production.up.railway.app/api';
const USER_COOKIE = 'user_data';
const TOKEN_COOKIE = 'auth_token';

const AuthService = {
  register: async (email, password, acceptedTerms) => {
    try {
      const response = await axios.post(`${API_URL}/register`, {
        email,
        password,
        acceptedTerms
      });
      if (response.data.token) {
        AuthService.setUserWithExpiry({
          email: email,
          token: response.data.token,
          expiresAt: response.data.expiresAt
        });
      }
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },

  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/login`, {
        email,
        password
      });
      if (response.data.token) {
        const user = { 
          email: email,
          token: response.data.token,
          expiresAt: response.data.expiresAt
        };
        AuthService.setUserWithExpiry(user);
        return user;
      }
      throw new Error('Login failed: No token received');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data);
      }
      throw error;
    }
  },

  setUserWithExpiry: (user) => {
    const now = new Date();
    const expiryDate = new Date(user.expiresAt || now.getTime() + 3600000);
    
    Cookies.set(USER_COOKIE, JSON.stringify({
      email: user.email,
      expiresAt: user.expiresAt
    }), { expires: expiryDate, secure: true, sameSite: 'strict' });
    
    Cookies.set(TOKEN_COOKIE, user.token, { expires: expiryDate, secure: true, sameSite: 'strict' });
  },

  checkSubscriptionStatus: async (userId) => {
    const token = AuthService.getAuthToken();
    try {
      const response = await axios.get(`${API_URL}/user/subscription`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      return {
        isSubscribed: response.data.isSubscribed,
        hiddenValues: response.data.hiddenValues
      };
    } catch (error) {
      console.error('Error checking subscription status:', error);
      throw error;
    }
  },

  getCurrentUser: () => {
    const userStr = Cookies.get(USER_COOKIE);
    const token = Cookies.get(TOKEN_COOKIE);
    
    if (!userStr || !token) {
      return null;
    }
    
    const user = JSON.parse(userStr);
    const now = new Date();
    if (now.getTime() > new Date(user.expiresAt).getTime()) {
      AuthService.logout();
      return { isExpired: true };
    }
    
    return { ...user, token };
  },

  logout: () => {
    Cookies.remove(USER_COOKIE);
    Cookies.remove(TOKEN_COOKIE);
  },

  getAuthToken: () => {
    return Cookies.get(TOKEN_COOKIE) || null;
  },

  removeExpiredUser: () => {
    const user = AuthService.getCurrentUser();
    if (user && user.isExpired) {
      AuthService.logout();
      return { isExpired: true };
    }
    return null;
  }
};

export default AuthService;