import React from 'react';

const AboutPage = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Getting Started with Coding Beyond DSA</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Quick Fundamentals</h2>
        <p className="mb-4">
          Before we dive in, let's quickly cover some fundamental concepts:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Git:</strong> A version control system that helps you track changes in your code.
            <p className="mt-2 mb-4 bg-gray-100 p-2 rounded">
              Example: <code>git commit -m "Add login feature"</code><br/>
              This command saves your changes with a descriptive message.
            </p>
          </li>
          <li>
            <strong>Command Line:</strong> A text interface for your computer, useful for running development tools.
            <p className="mt-2 mb-4 bg-gray-100 p-2 rounded">
              Example: <code>cd projects</code><br/>
              This command changes your current directory to 'projects'.
            </p>
          </li>
          <li>
            <strong>IDEs:</strong> Integrated Development Environments, like VS Code, that make coding easier.
            <p className="mt-2 mb-4 bg-gray-100 p-2 rounded">
              Example: Visual Studio Code (VS Code)<br/>
              Features: Syntax highlighting, code completion, debugging tools.
            </p>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Front-end vs Back-end</h2>
        <p className="mb-4">
          Web development is often divided into two main areas:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Front-end:</strong> What users see and interact with. It renders components and creates the user interface.
            <p className="mt-2 mb-4 bg-gray-100 p-2 rounded">
              Example (React component):
              <pre className="mt-2">
                {`function Button() {
  return <button>Click me!</button>;
}`}
              </pre>
              This creates a simple button that users can see and click.
            </p>
          </li>
          <li>
            <strong>Back-end:</strong> The server-side of applications, handling data storage, processing, and APIs.
            <p className="mt-2 mb-4 bg-gray-100 p-2 rounded">
              Example (Java with Spring Boot):
              <pre className="mt-2">
                {`@RestController
public class UserController {
    @GetMapping("/api/users")
    public List<User> getUsers() {
        // Fetch users from database
        return userService.getAllUsers();
    }
}`}
              </pre>
              This Java code sets up an API endpoint that sends user data.
              It uses Spring Boot, a popular framework for building Java backends.
            </p>
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What are APIs?</h2>
        <p className="mb-4">
          API stands for Application Programming Interface. It's like a waiter in a restaurant:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>You (the front-end) ask the waiter (API) for a dish (data).</li>
          <li>The waiter goes to the kitchen (back-end) to get your order.</li>
          <li>The waiter brings back your dish (returns the data).</li>
        </ul>
        <p className="mb-4">
          Example: A weather app might use an API to get current temperature data from a server.
        </p>
        <p className="mb-4 bg-gray-100 p-2 rounded">
          Front-end (JavaScript): <code>fetch('https://api.weather.com/london')</code><br/>
          This fetches weather data for London from a weather service API.<br/>
          Back-end (Java) response: 
          <pre className="mt-2">
            {`@GetMapping("/weather/{city}")
public Weather getWeather(@PathVariable String city) {
    // Fetch weather data for the city
    return weatherService.getWeatherForCity(city);
}

// This might return:
// { "temp": 20, "condition": "Sunny" }`}
          </pre>
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Let's Begin the Journey!</h2>
        <p className="mb-4">
          Now that we have a basic understanding, we're ready to start our coding journey beyond DSA. We'll explore how to build real-world applications, diving deeper into front-end and back-end development, and learning how to connect them using APIs.
        </p>
        <p>
          Remember, coding is a skill that improves with practice. Don't be afraid to experiment and build your own projects along the way!
        </p>
      </section>
    </div>
  );
};

export default AboutPage;