import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Code, Book, CheckCircle, ArrowRight, Info, X } from 'lucide-react';
import axios from 'axios';

const TermsPrivacyModal = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('terms');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg max-w-3xl w-full max-h-[80vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex space-x-4">
            <button
              onClick={() => setActiveTab('terms')}
              className={`text-lg font-semibold ${activeTab === 'terms' ? 'text-blue-600' : 'text-gray-600'}`}
            >
              Terms and Conditions
            </button>
            <button
              onClick={() => setActiveTab('privacy')}
              className={`text-lg font-semibold ${activeTab === 'privacy' ? 'text-blue-600' : 'text-gray-600'}`}
            >
              Privacy Policy
            </button>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <div className="overflow-y-auto flex-grow p-4">
          {activeTab === 'terms' && (
            <div className="space-y-4 text-sm">
              <h2 className="text-xl font-semibold mb-4">Terms and Conditions</h2>
              <p>
                These Terms and Conditions, along with our privacy policy, constitute a binding agreement between CodingBeyondDSA ("we", "us", or "our") and you ("you" or "your") regarding your use of our website and services.
              </p>
              {/* Add more terms and conditions content here */}
            </div>
          )}
          {activeTab === 'privacy' && (
            <div className="space-y-4 text-sm">
              <h2 className="text-xl font-semibold mb-4">Privacy Policy</h2>
              <p>
                Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.
              </p>
              {/* Add more privacy policy content here */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PricingPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [razorpayLoaded, setRazorpayLoaded] = useState(false);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    contact: ''
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => setRazorpayLoaded(true);
    document.body.appendChild(script);

    return () => {
      const script = document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const benefits = [
    {
      icon: Code,
      title: "DevDaily",
      description: "Experience daily tasks that mirror real software development challenges:",
      points: [
        "Solve practical coding problems",
        "Work on realistic project scenarios",
        "Improve your problem-solving skills",
        "Learn industry-standard practices"
      ]
    },
    {
      icon: Book,
      title: "Master Learning Topics",
      description: "Dive deep into advanced programming concepts and emerging technologies:",
      points: [
        "Comprehensive curriculum covering key areas",
        "In-depth tutorials and explanations",
        "Hands-on projects to reinforce learning",
        "Stay updated with the latest tech trends"
      ]
    }
  ];

  const features = [
    "Unlimited access to DevDaily challenges",
    "Full library of Master Learning Topics",
    "Progress tracking and performance analytics",
    "Personalized learning paths",
    "Community forums for peer interaction",
    "Monthly live coding sessions with experts"
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubscribe = async () => {
    if (!razorpayLoaded) {
      console.error('Razorpay not loaded');
      alert('Payment system is not ready. Please try again later.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:8080/api/payment/create-order');
      const { orderId, amount, currency } = response.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: 'CodingBeyondDSA',
        description: 'Pro Developer Subscription',
        order_id: orderId,
        handler: async function (response) {
          try {
            const verificationResponse = await axios.post('https://codingbeyonddsa-backend-production.up.railway.app/api/payment/verify-payment', {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature
            });

            if (verificationResponse.data === "Payment successful!") {
              alert('Payment successful!');
              navigate('/dashboard');
            } else {
              alert('Payment verification failed. Please contact support.');
            }
          } catch (error) {
            console.error('Payment verification error:', error);
            alert('An error occurred during payment verification. Please contact support.');
          }
        },
        prefill: {
          name: userData.name,
          email: userData.email,
          contact: userData.contact
        },
        theme: {
          color: '#3B82F6'
        }
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on('payment.failed', function (response){
        console.error('Payment failed:', response.error);
        alert('Payment failed. Please try again.');
      });
      paymentObject.open();
    } catch (error) {
      console.error('Error creating order:', error);
      alert('An error occurred while initializing payment. Please try again.');
    }
  };

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-gray-50 to-white">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-6">Elevate Your Development Journey</h2>
        <p className="text-xl text-center text-gray-600 mb-12 max-w-3xl mx-auto">
          Combine daily practical challenges with in-depth learning to become an exceptional developer.
        </p>

        <div className="flex flex-col md:flex-row gap-8 items-stretch">
          {/* Benefits - Left Side */}
          <div className="md:w-1/2 bg-white p-8 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-6">Why Choose Our Platform?</h3>
            {benefits.map((benefit, index) => (
              <div key={index} className="mb-8">
                <div className="flex items-center mb-3">
                  <benefit.icon className="w-6 h-6 text-blue-600 mr-3" />
                  <h4 className="text-xl font-semibold">{benefit.title}</h4>
                </div>
                <p className="text-gray-600 mb-3">{benefit.description}</p>
                <ul className="space-y-2">
                  {benefit.points.map((point, idx) => (
                    <li key={idx} className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-1" />
                      <span className="text-sm text-gray-700">{point}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Subscription Package - Right Side */}
          <div className="md:w-1/2 bg-white p-8 rounded-lg shadow-md flex flex-col">
            <h3 className="text-2xl font-semibold mb-6">Subscribe to Our Pro Developer Plan</h3>
            <div className="space-y-4 mb-8">
              {features.map((feature, idx) => (
                <div key={idx} className="flex items-start">
                  <CheckCircle className="w-5 h-5 text-green-500 mr-3 mt-1" />
                  <p className="text-gray-700 text-sm">{feature}</p>
                </div>
              ))}
            </div>

            {/* User Info Form */}
            <div className="space-y-4 mb-8">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={userData.name}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={userData.email}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                required
              />
              <input
                type="tel"
                name="contact"
                placeholder="Your Contact Number"
                value={userData.contact}
                onChange={handleInputChange}
                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                required
              />
            </div>

            {/* No Refund Policy */}
            <p className="text-sm text-red-600 font-semibold mb-4">
              Please note: We do not offer refunds on subscriptions.
            </p>

            <button
              onClick={handleSubscribe}
              className="w-full bg-blue-600 text-white font-semibold py-3 rounded-md flex items-center justify-center"
            >
              Start Your Journey <ArrowRight className="ml-2" />
            </button>

            <p className="text-sm text-gray-600 mt-4 text-center">
              By subscribing, you agree to our{' '}
              <button
                onClick={() => setIsModalOpen(true)}
                className="text-blue-600 hover:underline"
              >
                Terms and Conditions
              </button>{' '}
              and{' '}
              <button
                onClick={() => setIsModalOpen(true)}
                className="text-blue-600 hover:underline"
              >
                Privacy Policy
              </button>.
            </p>
          </div>
        </div>
      </div>

      <TermsPrivacyModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </section>
  );
};

export default PricingPage;