import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(null);
  const [error, setError] = useState(null);

  const { planName, planPrice } = location.state || {};

  useEffect(() => {
    if (!planName || !planPrice) {
      navigate('/pricing');
      return;
    }

    const loadRazorpay = async () => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.async = true;
      document.body.appendChild(script);
    };

    loadRazorpay();
  }, [planName, planPrice, navigate]);

  useEffect(() => {
    const createOrder = async () => {
      try {
        const response = await axios.post('https://codingbeyonddsa-backend-production.up.railway.app/api/create-order', { amount: planPrice * 100 }); // Razorpay expects amount in paise
        setOrderId(response.data.id);
      } catch (err) {
        setError('Failed to create order. Please try again.');
        console.error('Error creating order:', err);
      }
    };

    if (planPrice) {
      createOrder();
    }
  }, [planPrice]);

  const handlePayment = () => {
    if (!orderId) {
      setError('Order not created. Please try again.');
      return;
    }

    const options = {
      key: 'YOUR_RAZORPAY_KEY_ID', // Replace with your actual Razorpay Key ID
      amount: planPrice * 100, // Amount in paise
      currency: 'INR',
      name: 'Your Company Name',
      description: `${planName} Plan Subscription`,
      order_id: orderId,
      handler: async (response) => {
        try {
          const verificationResponse = await axios.post('https://codingbeyonddsa-backend-production.up.railway.app/api/verify-payment', response);
          if (verificationResponse.data.success) {
            // Payment successful, redirect or show success message
            navigate('/dashboard', { state: { message: 'Payment successful! Your subscription is now active.' } });
          } else {
            setError('Payment verification failed. Please contact support.');
          }
        } catch (err) {
          setError('An error occurred. Please try again.');
          console.error('Error verifying payment:', err);
        }
      },
      prefill: {
        name: 'John Doe', // You can prefill this with the user's name if available
        email: 'john@example.com', // You can prefill this with the user's email if available
      },
      theme: {
        color: '#3399cc'
      }
    };

    const razorpayInstance = new window.Razorpay(options);
    razorpayInstance.open();
  };

  if (!planName || !planPrice) {
    return null; // This will prevent rendering while redirecting
  }

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h2 className="text-2xl font-bold mb-6 text-center">Complete Your Purchase</h2>
        <p className="text-center mb-6">
          You've selected the <span className="font-semibold">{planName}</span> plan for <span className="font-semibold">₹{planPrice}/month</span>.
        </p>
        
        {error && (
          <div className="text-red-500 text-center mb-4 p-3 bg-red-100 rounded">{error}</div>
        )}

        <button 
          onClick={handlePayment}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          Pay Now
        </button>

        <button 
          onClick={() => navigate('/pricing')}
          className="mt-4 w-full bg-gray-200 text-gray-800 py-2 px-4 rounded-full hover:bg-gray-300 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          Back to Pricing
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;