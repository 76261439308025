import React, { useState } from 'react';
import { Calendar, MessageSquare, Users, Video } from 'lucide-react';

const StreamSchedule = () => {
  const schedule = [
    { day: "Monday", time: "8:00 PM", game: "Fortnite" },
    { day: "Wednesday", time: "9:00 PM", game: "Among Us" },
    { day: "Friday", time: "7:00 PM", game: "Minecraft" },
    { day: "Saturday", time: "3:00 PM", game: "Just Chatting" },
  ];

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-4 border-b">
        <h3 className="text-lg font-semibold flex items-center">
          <Calendar className="mr-2" size={20} />
          Stream Schedule
        </h3>
      </div>
      <div className="p-4">
        <ul className="space-y-2">
          {schedule.map((stream, index) => (
            <li key={index} className="flex justify-between items-center">
              <span>{stream.day}</span>
              <span>{stream.time}</span>
              <span className="text-blue-500">{stream.game}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ChatMessage = ({ user, message }) => (
  <div className="flex items-start mb-2">
    <div className="h-8 w-8 mr-2 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
      <img src={user.avatar} alt={user.name} className="h-full w-full object-cover" />
    </div>
    <div>
      <span className="font-bold text-blue-500">{user.name}: </span>
      <span>{message}</span>
    </div>
  </div>
);

const TwitchStreamPage = () => {
  const [chatMessages, setChatMessages] = useState([
    { user: { name: "User1", avatar: "/api/placeholder/32/32" }, message: "Hey everyone!" },
    { user: { name: "User2", avatar: "/api/placeholder/32/32" }, message: "Great stream!" },
    { user: { name: "User3", avatar: "/api/placeholder/32/32" }, message: "LOL" },
  ]);
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      setChatMessages([...chatMessages, { user: { name: "You", avatar: "/api/placeholder/32/32" }, message: newMessage }]);
      setNewMessage("");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">My Twitch Channel</h1>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <div className="bg-white rounded-lg shadow-md overflow-hidden mb-4">
            <div className="p-0">
              {/* This would be replaced with an actual Twitch embed */}
              <div className="bg-gray-800 h-96 flex items-center justify-center text-white">
                <Video size={48} />
                <span className="ml-2">Live Stream Placeholder</span>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-4 border-b">
              <h3 className="text-lg font-semibold flex items-center">
                <Users className="mr-2" size={20} />
                Stream Info
              </h3>
            </div>
            <div className="p-4">
              <h2 className="text-xl font-bold">Playing Fortnite</h2>
              <p className="text-gray-600">Join me for some battle royale action!</p>
              <div className="mt-2">
                <span className="bg-red-500 text-white px-2 py-1 rounded text-sm mr-2">LIVE</span>
                <span className="text-gray-600">1.2K viewers</span>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="p-4 border-b">
              <h3 className="text-lg font-semibold flex items-center">
                <MessageSquare className="mr-2" size={20} />
                Live Chat
              </h3>
            </div>
            <div className="p-4">
              <div className="h-64 overflow-y-auto mb-4">
                {chatMessages.map((msg, index) => (
                  <ChatMessage key={index} user={msg.user} message={msg.message} />
                ))}
              </div>
              <form onSubmit={handleSendMessage} className="flex">
                <input
                  type="text"
                  placeholder="Type a message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="flex-grow mr-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button 
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
          <StreamSchedule />
        </div>
      </div>
    </div>
  );
};

export default TwitchStreamPage;