import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PricingPage from './pages/PricingPage';
import FAQPage from './pages/FAQPage';
import AboutPage from './pages/AboutPage';
import Login from './components/Login';
import SignUp from './components/SignUp';
import MainDashboard from './components/Dashboard/MainDashboard';
import { AuthProvider, useAuth } from './auth/AuthContext';
import UserProfile from './components/UserProfile';
import ProtectedRoute from './auth/ProtectedRoute';
import PaymentPage from './components/Payment';
import DSACheatSheet from './components/DSACheatSheet';
import TwitchStreamPage from './components/ExpertTalk';

// Existing components
const CreatorStats = () => { /* ... existing code ... */ };

// HomePage component
const HomePage = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleLaunch = () => {
    window.location.href = '/signup';
  };

  const handleGetStartedFree = () => {
    alert('Free start functionality would be implemented here!');
  };

  return (
    <section className="py-20 px-4">
      <div className="max-w-3xl mx-auto">
        <CreatorStats />
        <h2 className={`text-4xl sm:text-5xl md:text-6xl font-bold text-gray-800 mb-4 text-center transition-all duration-1000 ease-out ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}>
          <span className="block mb-2">Master Real World</span>
          <span className="block">
            Coding{' '}
            <span className="text-blue-600">
              Skills
            </span>
          </span>
        </h2>
        <p className={`text-xl text-gray-600 mb-8 text-center transition-all duration-1000 ease-out ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}>
          The practical, real-world nature of the challenges
        </p>
        <div className={`mt-8 flex flex-col sm:flex-row justify-center gap-4 transition-all duration-1000 ease-out ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
        }`}>
          <button
            onClick={handleLaunch}
            className="px-6 py-2 bg-blue-600 text-white text-base font-medium rounded-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition-colors duration-200"
          >
            Start Practicing
          </button>
          <button
            onClick={handleGetStartedFree}
            className="px-6 py-2 bg-white text-blue-600 text-base font-medium rounded-full shadow-sm hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transition-colors duration-200 border border-blue-200"
          >
            Competitions
          </button>
        </div>
      </div>
    </section>
  );
};

// Header component
const Header = () => {
  const { user } = useAuth();
  console.log("Current user:", user); // Debug log

  const handleSignIn = () => {
    window.location.href = '/login';
  };

  return (
    <header className="bg-white">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <span className="text-xl font-semibold text-black">CodingBeyondDSA</span>
        </Link>
        <nav className="flex items-center">
          <Link to="/devdaily" className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200">
            DevDaily
          </Link>
          <Link to="/beyondDSA" className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200">
            BeyondDSA
          </Link>
          <Link to="/DSACheatSheet" className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200">
            DSA Cheatsheet
          </Link>
          <Link to="/expertTalk" className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200">
            Expert Talk
          </Link>
          <Link to="/about" className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200">
            How to Get Started
          </Link>
          <Link to="/pricing" className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200">
            Pricing
          </Link>
          {user ? (
            <UserProfile />
          ) : (
            <button 
              onClick={handleSignIn}
              className="mx-2 px-3 py-1 text-sm text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200"
            >
              Log In
            </button>
          )}
        </nav>
      </div>
    </header>
  );
};

// Main App component
const App = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
  };

  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen bg-gray-50">
          <Helmet>
            <title>Coding Beyond DSA</title>
            <link rel="icon" type="image/png" href="/logo192.png" />
          </Helmet>
          <Header />

          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/devdaily" element={
                <ProtectedRoute>
                  <FAQPage />
                </ProtectedRoute>
              } />
              <Route path="/about" element={<AboutPage />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/expertTalk" element={
                <ProtectedRoute>
                  <TwitchStreamPage />
                </ProtectedRoute>} />
              <Route path='/dsaCheatSheet' element={
                <ProtectedRoute>
                  <DSACheatSheet/>
                </ProtectedRoute>
              }/>
              <Route path="/beyondDSA" element={
                <ProtectedRoute>
                  <MainDashboard />
                </ProtectedRoute>
              } />
            </Routes>
          </main>

          <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4 text-center">
              <p>&copy; 2024 CodingBeyondDSA. All rights reserved.</p>
            </div>
          </footer>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;