import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import AuthService from '../auth/AuthService';
import { motion } from 'framer-motion';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!acceptedTerms) {
      setError('You must accept the terms and conditions');
      return;
    }

    try {
      const response = await AuthService.register(email, password, acceptedTerms);
      if (response.token) {
        await login(email, password);
        navigate('/beyondDSA', { state: { message: 'Registration successful!' } });
      } else {
        setError('Registration failed. Please try again.');
      }
    } catch (error) {
      console.log(error.response)
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        console.log(error.response.data.error)
        switch (errorData.error) {
          case 'EMAIL_IN_USE':
            setError('Email is already in use. Please proceed to login');
            navigate('/login', { state: { message: 'Email already in use. Please log in.' } });
            break;
          case 'TERMS_NOT_ACCEPTED':
            setError('You must accept the terms and conditions to register.');
            break;
          case 'REGISTRATION_FAILED':
            setError('Registration failed. Please try again later.');
            break;
          default:
            setError(errorData.message || 'An error occurred during registration');
        }
      } else {
        setError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  const welcomeVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.1
      }
    }
  };

  const letterVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="px-10 py-12">
            <motion.h2 
              className="text-3xl font-light text-gray-800 text-center mb-8"
              variants={welcomeVariants}
              initial="hidden"
              animate="visible"
            >
              {"Join".split('').map((letter, index) => (
                <motion.span key={index} variants={letterVariants} className="inline-block">
                  {letter === ' ' ? '\u00A0' : letter}
                </motion.span>
              ))}
              <br />
              <span className="font-medium bg-clip-text text-transparent bg-gradient-to-r from-gray-700 to-gray-500">
                {"CodingBeyondDSA".split('').map((letter, index) => (
                  <motion.span key={index} variants={letterVariants} className="inline-block">
                    {letter}
                  </motion.span>
                ))}
              </span>
            </motion.h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-gray-500"
                  required
                />
              </div>
              <div className="flex items-center">
                <input
                  id="terms"
                  type="checkbox"
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                  className="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded"
                  required
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
                  I accept the terms and conditions
                </label>
              </div>
              {error && (
                <motion.p 
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-red-500 text-sm"
                >
                  {error}
                </motion.p>
              )}
              <div>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-200"
                >
                  Sign Up
                </motion.button>
              </div>
            </form>
          </div>
          <div className="px-10 py-6 bg-gray-50 border-t border-gray-200">
            <p className="text-sm text-center text-gray-600">
              Already have an account?{' '}
              <Link to="/login" className="font-medium text-gray-700 hover:text-gray-500 transition duration-200">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default SignUp;