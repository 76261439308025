import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import AuthService from '../auth/AuthService';

const ProtectedRoute = ({ children }) => {
  const { user, isSessionExpired } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hiddenValues, setHiddenValues] = useState('N');
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        // Assuming you have an API endpoint to check subscription status
        const response = await AuthService.checkSubscriptionStatus(user.id);
        setIsSubscribed(response.isSubscribed);
        setHiddenValues(response.hiddenValues);
      } catch (error) {
        console.error('Error checking subscription status:', error);
        // Handle error - maybe set a default value or show an error message
      } finally {
        setIsLoading(false);
      }
    };

    checkUserStatus();
  }, [user]);

  if (isSessionExpired) {
    return <Navigate to="/login" state={{ message: 'Your session has expired. Please log in again.' }} />;
  }

  if (isLoading) {
    return <div>Loading...</div>; // Or a more sophisticated loading component
  }

  if (!user) {
    return <Navigate to="/login" state={{ message: 'Please log in to access this page.' }} />;
  }

  // Inject the subscription status and hidden values into the children components
  return React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isSubscribed, hiddenValues });
    }
    return child;
  });
};

export default ProtectedRoute;