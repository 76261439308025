import React, { useState } from 'react';
import { Code, GitBranch, Bug, Users, Clock, Coffee, Search, AlertCircle, CheckCircle } from 'lucide-react';

const tasks = [
  {
    id: 1,
    title: "Code Reviews",
    icon: <Code className="mr-2" size={20} />,
    items: [
      "Review pull request #142 for the user authentication module",
      "Address feedback on your frontend optimization PR",
      "Perform a final review on the database schema changes",
      "Review the new API endpoint implementation",
      "Provide feedback on the refactored utility functions"
    ]
  },
  {
    id: 2,
    title: "Bug Fixes",
    icon: <Bug className="mr-2" size={20} />,
    items: [
      "Investigate reported memory leak in the data processing service",
      "Fix race condition in the concurrent job scheduler",
      "Debug and fix the user session timeout issue",
      "Address the CSS layout bug in the responsive design",
      "Fix the data inconsistency in the reporting module"
    ]
  },
  {
    id: 3,
    title: "Performance Optimization",
    icon: <Search className="mr-2" size={20} />,
    items: [
      "Profile and optimize the database queries in the search function",
      "Implement caching for frequently accessed data",
      "Optimize the image loading process for faster page renders",
      "Reduce bundle size by code splitting and lazy loading",
      "Improve the algorithm efficiency in the data processing pipeline"
    ]
  },
  {
    id: 4,
    title: "Code Refactoring",
    icon: <Coffee className="mr-2" size={20} />,
    items: [
      "Refactor the authentication middleware for better modularity",
      "Apply SOLID principles to the order processing module",
      "Convert class components to functional components with hooks",
      "Standardize error handling across the application",
      "Improve code reusability in the UI component library"
    ]
  },
  {
    id: 5,
    title: "DevOps Tasks",
    icon: <GitBranch className="mr-2" size={20} />,
    items: [
      "Set up automated deployment pipeline for the new microservice",
      "Configure monitoring and alerting for critical services",
      "Optimize Docker images for faster build times",
      "Update the Kubernetes cluster configuration",
      "Implement log aggregation for better debugging"
    ]
  }
];

const SoftwareEngineeringDashboard = () => {
  const [selectedTask, setSelectedTask] = useState(tasks[0]);
  const [completedItems, setCompletedItems] = useState({});

  const toggleItemCompletion = (taskId, itemIndex) => {
    setCompletedItems(prev => ({
      ...prev,
      [taskId]: {
        ...prev[taskId],
        [itemIndex]: !prev[taskId]?.[itemIndex]
      }
    }));
  };

  const getCompletedCount = (taskId) => {
    return Object.values(completedItems[taskId] || {}).filter(Boolean).length;
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Panel (Tasks) */}
      <div className="w-64 bg-white shadow-md p-4 overflow-y-auto">
        <h2 className="text-xl font-bold mb-4 text-black">Daily Tasks</h2>
        {tasks.map((task) => (
          <div
            key={task.id}
            className={`cursor-pointer p-3 mb-2 rounded-md transition duration-300 text-sm flex items-center justify-between ${
              selectedTask.id === task.id 
                ? 'bg-gray-200 text-black' 
                : 'hover:bg-gray-100 text-gray-600'
            }`}
            onClick={() => setSelectedTask(task)}
          >
            <div className="flex items-center">
              {task.icon}
              <span className="font-medium">{task.title}</span>
            </div>
            <span className="text-xs font-semibold bg-green-100 text-green-800 px-2 py-1 rounded-full">
              {getCompletedCount(task.id)}/{task.items.length}
            </span>
          </div>
        ))}
      </div>

      {/* Right Content Area */}
      <div className="flex-1 p-6 overflow-y-auto bg-gray-50">
        {/* Header */}
        <div className="mb-6 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-black">Software Engineering Tasks</h1>
          <div className="flex items-center text-gray-600">
            <Clock size={20} className="mr-2" />
            <span className="text-sm font-medium">Updated daily</span>
          </div>
        </div>

        {/* Task Content */}
        <div className="bg-white p-6 rounded-lg shadow-md border border-gray-200">
          <h2 className="text-2xl font-bold mb-4 text-black flex items-center justify-between">
            <div className="flex items-center">
              {selectedTask.icon}
              <span className="ml-2">{selectedTask.title}</span>
            </div>
            <span className="text-sm font-semibold bg-blue-100 text-blue-800 px-3 py-1 rounded-full">
              {getCompletedCount(selectedTask.id)}/{selectedTask.items.length} Completed
            </span>
          </h2>
          
          <ul className="space-y-3">
            {selectedTask.items.map((item, index) => (
              <li key={index} className="flex items-start">
                <div 
                  className="cursor-pointer mr-2 mt-1"
                  onClick={() => toggleItemCompletion(selectedTask.id, index)}
                >
                  {completedItems[selectedTask.id]?.[index] ? (
                    <CheckCircle className="text-green-500" size={20} />
                  ) : (
                    <div className="w-5 h-5 border-2 border-gray-300 rounded-full" />
                  )}
                </div>
                <span className={`text-gray-700 ${completedItems[selectedTask.id]?.[index] ? 'line-through' : ''}`}>
                  {item}
                </span>
              </li>
            ))}
          </ul>
        </div>

        {/* Reminder */}
        <div className="mt-6 bg-blue-50 p-4 rounded-md shadow-md border border-blue-200">
          <h3 className="text-lg font-semibold mb-2 text-blue-800 flex items-center">
            <AlertCircle className="mr-2" size={20} />
            Daily Reminder
          </h3>
          <p className="text-blue-600 text-sm">
            Don't forget to commit your changes, update your task board, and communicate any blockers to your team!
          </p>
        </div>
      </div>
    </div>
  );
};

export default SoftwareEngineeringDashboard;