import React, { createContext, useState, useContext, useEffect } from 'react';
import AuthService from './AuthService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children, onSessionExpired }) => {
  const [user, setUser] = useState(null);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const checkUser = () => {
      const currentUser = AuthService.getCurrentUser();
      if (currentUser && currentUser.isExpired) {
        setUser(null);
        setIsSessionExpired(true);
        onSessionExpired(); // Call the passed function instead of using navigate
      } else if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
      }
    };

    checkUser();
    const interval = setInterval(checkUser, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [onSessionExpired]);

  const login = async (email, password) => {
    try {
      const userData = await AuthService.login(email, password);
      setUser(userData);
      setIsSessionExpired(false);
      return userData;
    } catch (error) {
      console.error("Login error in AuthContext:", error);
      throw error;
    }
  };

  const logout = () => {
    AuthService.logout();
    setUser(null);
    setIsSessionExpired(false);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isSessionExpired }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);